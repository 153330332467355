<div class="block-1">
  <div class="desc">
    <h1>智能接口数据中心</h1>
    <h2>Application Program Interface Provider of Winning Numbers.</h2>
    <div class="feature">
      致力于为网络媒体、行业客户、第三方销售或分析<br>机构提供专业权威的接口数据。
    </div>
    <button mat-raised-button color="accent" style="width: 100px; background: #C80000;" routerLink="/open-api">快速查看</button>
    <!-- <div class="quickSelect">
      <mat-form-field appearance="fill">
        <mat-label>选择采种</mat-label>
        <mat-select value="option1" ngClass="test888">
          <mat-optgroup label="时时彩">
            <mat-option>None</mat-option>
            <mat-option value="option1">Option 1</mat-option>
            <mat-option value="option2">Option 2</mat-option>
            <mat-option value="option3">Option 3</mat-option>
          </mat-optgroup>
          <mat-optgroup label="高频彩">
            <mat-option value="option4">Option 4</mat-option>
            <mat-option value="option5">Option 5</mat-option>
            <mat-option value="option6">Option 6</mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
      <button mat-raised-button color="accent">快速查看</button>
    </div> -->
  </div>
</div>
<div class="curve">
  <div class="container">
    <!-- <h2>核心优势技术实力 / Advantage</h2> -->
  </div>
  <div class="container">
    <div class="row listbar listbar-1">
      <div class="col-sm-7">
        <h1 class="head"><i class="fa fa-codepen" style="color: #C40000;"></i>提供彩票开奖号码数据接口</h1>
        <h5 class="body">面向彩票相关的行业软件、手机应用、编程爱好者等，提供开奖号码的标准化编程接口。</h5>
      </div>
    </div>

    <div class="row listbar listbar-2">
      <div class="col-sm-10 offset-sm-4">
        <h1 class="head"><i class="fa fa-ge" style="color: #C40000;"></i>覆盖国内及世界各地彩票开奖号码</h1>
        <h5 class="body">从国内众多省份彩票中心提取开奖数据，号码更新及时，全面覆盖国内绝大部份的彩种的开奖信息。</h5>
      </div>
    </div>

    <div class="row listbar listbar-3">
      <div class="col-sm-7">
        <h1 class="head"><i class="fa fa-joomla" style="color: #C40000;"></i>支持多平台、多应用的调用方式</h1>
        <h5 class="body">提供XML与JSON两种数据格式。全面支持Win/iOS/Android等所有操作系统下各种应用调用。另外支持javascript跨域jsonp调用。</h5>
      </div>
    </div>

    <div class="row listbar listbar-4">
      <div class="col-sm-7 offset-sm-5">
        <h1 class="head"><i class="fa fa-jsfiddle" style="color: #C40000;"></i>高并发策略与集群式引擎架构</h1>
        <h5 class="body">采用阿里云的大数据处理引擎架构。建立分布式弹性集群，最大支持1亿次/小时的高并发策略，确保接口稳定。</h5>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>