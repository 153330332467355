import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LotteryService } from '../core/service/lottery.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
  title = '';
  code = '';
  constructor(
    private route: ActivatedRoute,
    public lotterySer: LotteryService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(v => {
      this.code = v.get('code');
      this.title = this.lotterySer.lotteryByCode[this.code].name;
    });
  }

}
