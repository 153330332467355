import { Component, OnInit, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  @ViewChild('toolbar') toolbar: HTMLElement;

  constructor() { }

  ngOnInit(): void {
    // fromEvent(window, 'scroll')
    //     .pipe(throttleTime(300))
    //     .subscribe(e => {
    //       console.log(e);
    //       this.toolbar.classList.add('shadow');
    //     });
  }

}
