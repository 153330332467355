import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { LotteryService } from '../core/service/lottery.service';
import { LotteryGameMenuGroup } from './../core/schema';

@Component({
  selector: 'app-open-api',
  templateUrl: './open-api.component.html',
  styleUrls: ['./open-api.component.scss']
})
export class OpenApiComponent implements OnInit {
  public search = '';
  filteredLotterys;
  constructor(
    private apollo: Apollo,
    public lotterySer: LotteryService
  ) { }

  ngOnInit(): void {
  }

  setActiveGroup(group: LotteryGameMenuGroup): void{
    this.lotterySer.activeGroup = group;
  }

  filter(e) {
    this.search = e.target.value;
    if(this.search === '') {
      this.filteredLotterys = null;
      return;
    }
    const lotteryByCode = this.lotterySer.lotteryByCode;
    this.filteredLotterys = Object.keys(lotteryByCode).reduce((lotterys, k) => {
      if (lotteryByCode[k].code.includes(this.search) || lotteryByCode[k].name.includes(this.search)) {
        lotterys.push(lotteryByCode[k]);
      }
      return lotterys;
    }, []);
  }

}
