import { AnnouncementContentComponent } from './announcement/announcement-content/announcement-content.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnnouncementComponent } from './announcement/announcement.component';
import { DetailComponent } from './detail/detail.component';
import { HistoryComponent } from './history/history.component';
import { HomeComponent } from './home/home.component';
import { LayoutComponent } from './layout/layout.component';
import { OpenApiComponent } from './open-api/open-api.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'open-api',
        component: OpenApiComponent
      },
      {
        path: 'detail/:code',
        component: DetailComponent
      },
      {
        path: 'history/:code',
        component: HistoryComponent
      },
      {
        path: 'announcement',
        component: AnnouncementComponent,
      },
      {
        path: 'announcement/:id',
        component: AnnouncementContentComponent,
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
