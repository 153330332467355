<ng-container *ngIf="lotterySer.loading">
  <div style="display: flex; justify-content: center; align-items: center; width: 100%; height: 100%;">
    <mat-spinner></mat-spinner>
  </div>
</ng-container>
<ng-container *ngIf="!lotterySer.loading">
  <router-outlet></router-outlet>
</ng-container>
<!-- <div class="block-1">
  <div class="desc">
    <h1>开奖号码API(接口)供应商</h1>
    <h2>Application Program Interface Provider of Winning Numbers.</h2>
    <div class="feature">
      快速 - 同时包括线上与线下的第一时间数据采集来源<br>
      准确 - 严谨的分布式清洗方案，以确保号码的正确与稳定<br>
      稳定 - 基于杭州阿里云的高防服务，与其安全保障服务<br>
    </div>
    <div class="quickSelect">
      <mat-form-field appearance="fill">
        <mat-label>选择采种</mat-label>
        <mat-select value="option1" ngClass="test888">
          <mat-optgroup label="时时彩">
            <mat-option>None</mat-option>
            <mat-option value="option1">Option 1</mat-option>
            <mat-option value="option2">Option 2</mat-option>
            <mat-option value="option3">Option 3</mat-option>
          </mat-optgroup>
          <mat-optgroup label="高频彩">
            <mat-option value="option4">Option 4</mat-option>
            <mat-option value="option5">Option 5</mat-option>
            <mat-option value="option6">Option 6</mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
      <button mat-raised-button color="accent">快速查看</button>
    </div>
  </div>
</div> -->
