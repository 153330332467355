<div
  style="
  background: #ffffff;
  height: 126px;
  box-shadow: 1px 1px 5px #00000021;
  display: flex;
  align-items: center;
  "

>
  <div class="container filter-block" style="padding-top: 0;">
    <div class="f-left">
      分 类：
      <button *ngFor="let item of this.lotterySer.menu" mat-button mat-raised-button class="mr-2"
        [color]="(this.lotterySer.activeGroup === item && search === '') ? 'primary' : ''"
        (click)="setActiveGroup(item)"
      >
        {{item.name}}
      </button>
      <!-- <button mat-raised-button class="mr-2" color="primary">全国彩</button>
      <button mat-raised-button class="mr-2" color="">热门彩</button>
      <button mat-raised-button class="mr-2" color="">境内高频</button>
      <button mat-raised-button class="mr-2" color="">境外高频</button>
      <button mat-raised-button class="mr-2" color="">境内低频</button>
      <button mat-raised-button class="mr-2" color="">境外低频</button> -->
    </div>
    <div  class="f-right">
      <mat-form-field class="mr-2">
        <mat-label>彩种搜索</mat-label>
        <input matInput placeholder="输入彩种名称" value="" (input)="filter($event)">
      </mat-form-field>
      <button mat-button mat-raised-button class="" color="primary">搜索</button>
    </div>
  </div>
</div>
<div class="container" style="min-height: 80vh;">
  <div class="info" *ngIf="this.filteredLotterys?.length === 0 && this.search != ''">没有找到你要的接口数据</div>
  <div class="row">
    <div *ngFor="let item of (this.filteredLotterys != null ? this.filteredLotterys : this.lotterySer.activeGroup?.list); let idx = index" class="col-md-4 col-sm-6 col-xs-12">
      <mat-card class="card">
        <mat-card-header>
          <!-- <div mat-card-avatar class="example-header-image" style="background-image: url(https://via.placeholder.com/150/666666/FFFFFF/?text={{ item.code[0] | uppercase }})"></div> -->
          <mat-card-title>{{item.name}}</mat-card-title>
          <mat-card-subtitle>{{item.code}}</mat-card-subtitle>
        </mat-card-header>
        <!-- <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu"> -->
        <mat-card-content>
          <!-- 停售 -->
          <ng-container *ngIf="this.lotterySer.lotteryByCode[item.code].isStopSelling">
            <div class="stopSelling">
              <img src="/assets/stop.svg">
              <div class="desc">官方停售</div>
            </div>
          </ng-container>
          <ng-container *ngIf="!this.lotterySer.lotteryByCode[item.code].isStopSelling">
            <div style=" margin-bottom: 15px">
              第
              <span class="color-accent">{{ this.lotterySer.drawResultByCode[item.code]?.issue}}</span>
              期
              <span style="float: right; color: #999999; ">
                {{this.lotterySer.drawResultByCode[item.code]?.drawTime}}
              </span>
            </div>
            <div *ngIf="this.lotterySer.drawResultByCode[item.code]" style="text-align: center">
              <div *ngFor="let num of this.lotterySer.drawResultByCode[item.code]?.drawResult.split(','); let last= last;" class="ball"
                [class.special-ball]="last && (item.code === 'twlot' || item.code === 'fcdb')"
              >{{num}}</div>
            </div>
          </ng-container>

        </mat-card-content>
        <div style="text-align: center; height: 33px;">
          <ng-container *ngIf="!this.lotterySer.lotteryByCode[item.code].isStopSelling">
            <a class="action-btn"
              *ngIf="lotterySer.lotteryByCode[item.code]?.websiteUrl"
              mat-button target="_blank"
              href="{{lotterySer.lotteryByCode[item.code]?.websiteUrl}}"
            >官网</a>
            <button class="action-btn" mat-button routerLink="/detail/{{item.code}}">接口</button>
            <button class="action-btn" mat-button routerLink="/history/{{item.code}}">历史</button>
            <button class="action-btn" mat-button (click)="lotterySer.updateDrawResult(item.code)">刷新</button>
          </ng-container>
        </div>
      </mat-card>
    </div>
  </div>

  <!-- <hr class="hr1">
  <div class="info">没有找到你要的接口数据？请联系右下角客服告诉我们。如您有数据要入驻我们平台展示也可以联系我们。</div>
  <div class="row list">
    <div *ngFor="let item of lotterys; let idx = index" class="col-md-2 col-sm-3 col-xs-4">
      <div class="item" routerLink="/detail/1">
        <div class="circle g{{idx % 1}}">
          <span class="text">{{item.split('')[0]}}</span>
        </div>
        {{item}}
      </div>
    </div>
  </div> -->
</div>
<app-footer></app-footer>