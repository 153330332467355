<div class="top-block">
  <div class="container">
    {{title}}接口文档
    <button mat-raised-button color="" routerLink="/open-api">返回</button>
  </div>
</div>
<div class="container block">
  <div class="caiwui-select">
    <div class="caiwui-select-list">
      <div class="caiwui-select-title">彩种选择</div>
      <ul>
        <li *ngFor="let item of lotterySer.menu">
          {{item.name}}
          <div class="sub-menu">
            <div *ngFor="let item2 of item.list" class="btn" [ngClass]="{'disabled': this.lotterySer.lotteryByCode[item2.code].isStopSelling}" routerLink="/detail/{{item2.code}}">{{item2.name}}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <!-- <h1>
    重庆时时彩接口文档
    <button mat-button color="primary" routerLink="/open-api">返回</button>
  </h1> -->
  <h1>最新开奖结果</h1>
  <div class="sub-title">接口标识</div>
  <div>请求方式： GET</div>
  <div>
    接口位置：
    <a class="api-link" href="https://globalcaipiaokong.com/api/trial/draw-result?code={{this.code}}&rows=1" target="_blank">
      https://globalcaipiaokong.com/api/trial/draw-result?code={{this.code}}&rows=1
    </a>
  </div>
  <br>
  <a class="api-link" href="https://globalcaipiaokong.com/api/trial/draw-result?code={{this.code}}&rows=1" target="_blank">
    <button mat-raised-button color="accent">试试看</button>
  </a>

  <div class="sub-title">请求参数</div>
  <table class="table">
    <thead>
      <tr>
        <th>参数名</th>
        <th>类型</th>
        <th>必填</th>
        <th>描述</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>code</td>
        <td>字串</td>
        <td>是</td>
        <td>彩种代码</td>
      </tr>
      <tr>
        <td>rows</td>
        <td>整数</td>
        <td>否</td>
        <td>返回行数，默认 10</td>
      </tr>
    </tbody>
  </table>

  <div class="sub-title">返回参数</div>
  <table class="table">
    <thead>
      <tr>
        <th>参数名</th>
        <th>类型</th>
        <th>范例</th>
        <th>描述</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>code</td>
        <td>整数</td>
        <td>0</td>
        <td>状态码，0 表示成功</td>
      </tr>
      <tr>
        <td>msg</td>
        <td>字串</td>
        <td>成功</td>
        <td>消息</td>
      </tr>
      <tr>
        <td>data</td>
        <td>数组</td>
        <td>
          <pre>
<![CDATA[[
  {
    "cycleNo": 1307,
    "prevDrawTime": "2021-02-22 21:47:00",
    "issue": "202102221307",
    "drawResult": "6,3,4,8,2",
    "drawTime": "2021-02-22 21:47:00",
    "startTime": "2021-02-22 21:46:00",
    "endTime": "2021-02-22 21:47:00"
  }
]]]>
          </pre>
        </td>
        <td>开奖数据，<br>
          cycleNo 當日第几期<br>
          prevDrawTime 上一期开奖时间<br>
          issue 奖期<br>
          drawResult 开奖号码<br>
          drawTime 开奖时间<br>
          startTime 开售时间<br>
          endTime 封单时间
        </td>
      </tr>
    </tbody>
  </table>
  
  <h1 style="margin-top: 70px">开奖历史查找</h1>
  <div class="sub-title">接口标识</div>
    <div>请求方式： GET</div>
    <div>
      接口位置：
      <a class="api-link" href="https://globalcaipiaokong.com/api/trial/draw-result-by-datetime?code={{this.code}}&rows=10&start_time=2021-01-12%2000:00:00&end_time=2021-01-12%2023:59:59&page=1" target="_blank">
        https://globalcaipiaokong.com/api/trial/draw-result-by-datetime?code={{this.code}}&rows=10&start_time=2021-01-12%2000:00:00&end_time=2021-01-12%2023:59:59&page=1
      </a>
    </div>
    <br>
    <a class="api-link" href="https://globalcaipiaokong.com/api/trial/draw-result-by-datetime?code={{this.code}}&rows=10&start_time=2021-01-12%2000:00:00&end_time=2021-01-12%2023:59:59&page=1" target="_blank">
      <button mat-raised-button color="accent">试试看</button>
    </a>

    <div class="sub-title">请求参数</div>
    <table class="table">
      <thead>
        <tr>
          <th>参数名</th>
          <th>类型</th>
          <th>必填</th>
          <th>描述</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>code</td>
          <td>字串</td>
          <td>否</td>
          <td>彩种代码</td>
        </tr>
        <tr>
          <td>rows</td>
          <td>整数</td>
          <td>否</td>
          <td>返回行数，默认 10 上限 100</td>
        </tr>
        <tr>
          <td>start_time</td>
          <td>日期</td>
          <td>否</td>
          <td>起始日期 yyyy-MM-dd HH:mm:ss，默认值：昨天零点</td>
        </tr>
        <tr>
          <td>end_time</td>
          <td>日期</td>
          <td>否</td>
          <td>结束日期 yyyy-MM-dd HH:mm:ss，默认值：现在时间</td>
        </tr>
        <tr>
          <td>page</td>
          <td>整数</td>
          <td>否</td>
          <td>页数，默认值 1</td>
        </tr>
      </tbody>
    </table>

    <div class="sub-title">返回参数</div>
    <table class="table">
      <thead>
        <tr>
          <th>参数名</th>
          <th>类型</th>
          <th>范例</th>
          <th>描述</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>code</td>
          <td>整数</td>
          <td>0</td>
          <td>状态码，0 表示成功</td>
        </tr>
        <tr>
          <td>msg</td>
          <td>字串</td>
          <td>成功</td>
          <td>消息</td>
        </tr>
        <tr>
          <td>data.data</td>
          <td>数组</td>
          <td>
            <pre>
  <![CDATA[[
    {
      "id":1819,
      "code":"twssc",
      "issue":"110002436",
      "drawTime":"2021-01-12 23:55:00",
      "drawResult":"0,3,6,6,7"
    }
  ]]]>
            </pre>
          </td>
          <td>开奖数据<br>code 彩种代码<br>issue 奖期<br>drawResult 开奖号码<br>drawTime 开奖时间</td>
        </tr>
        <tr>
          <td>data.page</td>
          <td>数组</td>
          <td>
            <pre>
  <![CDATA[{
    "count":56,
    "current":"1",
    "total":6
  }]]>
            </pre>
          </td>
          <td>分页信息<br>count 数据总比数<br>current 当前页数<br>toral 总页数</td>
        </tr>
      </tbody>
    </table>
</div>