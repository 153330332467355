import { Injectable } from '@angular/core';
import { Apollo, gql, QueryRef } from 'apollo-angular';
import { DrawResult, LatestDrawResult, LotteryGame, LotteryGameMenuGroup } from '../schema';

@Injectable({
  providedIn: 'root'
})
export class LotteryService {
  loading = true;
  menu: LotteryGameMenuGroup[] = [];
  activeGroup: LotteryGameMenuGroup;
  lotteryByCode: {
    [code: string]: LotteryGame
  } = {};
  drawResultByCode: {
    [code: string]: LatestDrawResult
  };
  updateDrawResultQryRef: QueryRef<{drawHistory: DrawResult[]}>;
  constructor(
    private apollo: Apollo
  ) {
    this.init();
  }

  init(): void {
    this.apollo
      .watchQuery<{lotteryGameMenu: LotteryGameMenuGroup[], latestDrawResult: LatestDrawResult[], lotteryGames: LotteryGame[]}>({
        query: gql`
          query {
            lotteryGameMenu {
              id
              name
              order
              list {
                code
                name
              }
            }
            latestDrawResult {
              code
              name
              issue
              drawResult
              drawTime
            }
            lotteryGames{
              name
              code
              websiteUrl
              isStopSelling
            }
          }

        `,
      })
      .valueChanges.subscribe((result) => {
        if (!result.loading) {
          this.menu = result.data.lotteryGameMenu;


          // 關聯開獎
          this.drawResultByCode = result.data.latestDrawResult.reduce((drawByCode, draw) => {
            drawByCode[draw.code] = draw;
            return drawByCode;
          }, {});

          this.lotteryByCode = result.data.lotteryGames.reduce((resultByCode, lottery) => {
            resultByCode[lottery.code] = lottery;
            return resultByCode;
          }, {});

          if (this.menu[0]) {
            this.activeGroup = this.menu[1];
          }

          this.loading = false;
        }

      });
  }

  async updateDrawResult(code: string) {
    const qry  = gql`
        query drawHistory($code: String!){
          drawHistory(code: $code) {
            issue
            drawResult
            drawTime
          }
        }
      `;
    this.updateDrawResultQryRef = this.apollo.watchQuery({query: qry});

    const resp = await this.updateDrawResultQryRef.refetch({code});
    if (resp.data) {
      if (resp.data.drawHistory[0]) {
        const {drawResult, drawTime, issue} = resp.data.drawHistory[0];
        this.drawResultByCode[code] = {
          code, drawResult, drawTime, issue,
          name: this.drawResultByCode[code].name
        };
      }
    }
  }
}

