import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Apollo, gql, QueryRef } from 'apollo-angular';
import { Subscription } from 'rxjs';
import { AnnouncementPaginator } from '../core/schema';
@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent implements OnInit, OnDestroy {
  news: AnnouncementPaginator;
  announcementRef: QueryRef<{announcements: AnnouncementPaginator}>;
  announcementSub: Subscription;

  constructor(
    private apollo: Apollo
  ) { }

  ngOnInit(): void {
    this.getAnnouncements();
  }

  ngOnDestroy(): void {
    this.announcementSub?.unsubscribe();
  }

  getAnnouncements(page = 1): void {
    const qry  = gql`
    query announcements($rowsPerPage:Int, $page:Int){
      announcements(rowsPerPage: $rowsPerPage, page: $page) {
        paginatorInfo {
          count
          currentPage
          firstItem
          hasMorePages
          lastItem
          lastPage
          perPage
          total
        }
        data {
          id
          title
          startDateTime
          endDateTime
          order
          isShowInAnnouncePage
          isPopup
          isImportant
          isShowScrollingText
        }
      }
    }
  `;
    this.announcementRef = this.apollo.watchQuery<{announcements: AnnouncementPaginator}>({
      query: qry, variables: {rowsPerPage: 20, page}
    });
    this.announcementSub = this.announcementRef.valueChanges.subscribe(response => {
      if (!response.loading && response.data) {
        this.news = response.data.announcements;
      }
    });
  }

  handleChange(event: PageEvent): void {
    const page = event.pageIndex + 1;
    this.getAnnouncements(page);
  }
}
