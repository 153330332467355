<div class="container footer">
  <div class="row" style="padding: 0 20px;">
    <div class="col-sm-12">
      Copyright © 全球彩票控 All Rights Reserved					<p class="icpinfo">
        备案/许可证号：
        <a  target="_blank">蜀CP备19005834号</a>
      </p>
      <p class="win">增值电信业务经营许可证：蜀B8-2020208 蜀公网安备410001102000888号</p>
      
    </div>
  </div>
</div>