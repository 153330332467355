import { AnnouncementContentComponent } from './announcement/announcement-content/announcement-content.component';
import { AnnouncementComponent } from './announcement/announcement.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatPaginatorModule} from '@angular/material/paginator';
import { LayoutComponent } from './layout/layout.component';
import { HomeComponent } from './home/home.component';
import { OpenApiComponent } from './open-api/open-api.component';
import { FooterComponent } from './footer/footer.component';
import { DetailComponent } from './detail/detail.component';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { HistoryComponent } from './history/history.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule } from '@angular/forms';

import { SafeHtmlPipe } from './core/pipes/safeHtmlPipe';



const MatModules = [
  MatSliderModule,
  MatToolbarModule,
  MatIconModule,
  MatButtonModule,
  MatSelectModule,
  MatInputModule,
  MatCardModule,
  MatProgressSpinnerModule,
  MatPaginatorModule
];
@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HomeComponent,
    OpenApiComponent,
    FooterComponent,
    DetailComponent,
    HistoryComponent,
    AnnouncementComponent,
    AnnouncementContentComponent,
    SafeHtmlPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ...MatModules,
    GraphQLModule,
    HttpClientModule,
    FormsModule
  ],
  exports: [
    SafeHtmlPipe
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
