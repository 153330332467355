<div class="top-block">
  <div class="container d-flex align-items-center">
    {{title}}开奖历史
    <button mat-raised-button color="" routerLink="/open-api" class="ml-2">返回</button>
  </div>
</div>
<div class="container block" >
  <div class="caiwui-select">
    <div class="caiwui-select-list">
      <div class="caiwui-select-title">彩种选择</div>
      <ul>
        <li *ngFor="let item of lotterySer.menu">
          {{item.name}}
          <div class="sub-menu">
            <div *ngFor="let item2 of item.list" class="btn" [ngClass]="{'disabled': this.lotterySer.lotteryByCode[item2.code].isStopSelling}" routerLink="/history/{{item2.code}}">{{item2.name}}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="sub-title">开奖历史</div>
  <table class="table">
    <thead>
      <tr>
        <th>彩种名称</th>
        <th>开奖期号</th>
        <th>开奖时间</th>
        <th>开奖号码</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of result">
        <td>{{title}}</td>
        <td>{{item.issue}}</td>
        <td>{{item.drawTime}}</td>
        <td>
          <div class="ball" *ngFor="let n of item.drawResult.split(',')">{{n}}</div>

        </td>
      </tr>
      <!-- <tr>
        <td>code</td>
        <td>字串</td>
        <td>是</td>
        <td>彩种代码</td>
      </tr>
      <tr>
        <td>format</td>
        <td>字串</td>
        <td>是</td>
        <td>数据格式</td>
      </tr>
      <tr>
        <td>row</td>
        <td>整数</td>
        <td>是</td>
        <td>返回行数</td>
      </tr> -->
    </tbody>
  </table>
</div>