import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Apollo, gql, QueryRef } from 'apollo-angular';
import { Subscription } from 'rxjs/internal/Subscription';
import { Announcement } from 'src/app/core/schema';

@Component({
  selector: 'app-announcement-content',
  templateUrl: './announcement-content.component.html',
  styleUrls: ['./announcement-content.component.scss']
})
export class AnnouncementContentComponent implements OnInit, OnDestroy {
  routeSub: Subscription;
  content: Announcement;
  announcementSub: Subscription;
  announcementQryRef: QueryRef<{announcement: Announcement}>;
  constructor(
    public location: Location,
    private route: ActivatedRoute,
    private apollo: Apollo
  ) { }

  ngOnInit(): void {
    this.routeSub = this.route.params
    .subscribe(async (params) => {
      if (params.id) {
        this.getAnnouncementContent(params.id);
      }
    });
  }

  ngOnDestroy(): void {
    this.routeSub?.unsubscribe();
    this.announcementSub?.unsubscribe();
  }

  getAnnouncementContent(id: string): void {
    const qry  = gql`
    query announcement($id:ID!){
      announcement(id: $id){
        id
        title
        content
      }
    }
  `;
    this.announcementQryRef = this.apollo.watchQuery<{announcement: Announcement}>({
      query: qry, variables: {id}
    });
    this.announcementSub =  this.announcementQryRef.valueChanges.subscribe(response => {
      if (!response.loading && response.data) {
        this.content = response.data.announcement;
      }
    });
  }

}
