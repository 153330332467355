<mat-toolbar color="primary" class="toolbar mat-elevation-z6" #toolbar>
  <mat-toolbar-row>
    <a routerLink="home" class="title">
      <!-- <img src="/assets/logo.svg"> -->
      <img src="/assets/logo.png" class="text-logo">
    </a>
    <button mat-button routerLink="home">首页</button>
    <button mat-button routerLink="open-api">开奖接口</button>
    <button mat-button routerLink="announcement">行业新闻</button>
    <!-- <button mat-button>开奖号码</button>
    <button mat-button>价格与说明</button> -->
    <!-- <span class="example-spacer"></span>
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
      <mat-icon>menu</mat-icon>
    </button> -->
  </mat-toolbar-row>

  <!-- <mat-toolbar-row>
    <span>Second Line</span>
    <span class="example-spacer"></span>
    <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
      <mat-icon>favorite</mat-icon>
    </button>
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
      <mat-icon>share</mat-icon>
    </button>
  </mat-toolbar-row> -->
</mat-toolbar>
<div class="content">
  <router-outlet></router-outlet>
</div>