import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Apollo, gql, QueryRef } from 'apollo-angular';
import { Subscription } from 'rxjs';
import { DrawResult } from '../core/schema';
import { LotteryService } from '../core/service/lottery.service';



@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit, OnDestroy {
  title = '';
  code = '';
  result: DrawResult[] = [];
  queryRef: QueryRef<{drawHistory: DrawResult[]}>;
  querySub: Subscription;
  constructor(
    private route: ActivatedRoute,
    public lotterySer: LotteryService,
    private apollo: Apollo
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(v => {
      this.code = v.get('code');
      this.title = this.lotterySer.lotteryByCode[this.code].name;
      const qry  = gql`
        query drawHistory($code: String!){
          drawHistory(code: $code) {
            issue
            drawResult
            drawTime
          }
        }
      `;
      this.queryRef = this.apollo.watchQuery<{drawHistory: DrawResult[]}>({query: qry, variables: {code: this.code}});
      this.querySub = this.queryRef.valueChanges.subscribe(response => {
        if (!response.loading && response.data) {
          this.result = response.data.drawHistory;
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.querySub?.unsubscribe();
  }
}
