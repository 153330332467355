<div class="top-block">
  <div class="container d-flex align-items-center">
    行业新闻
  </div>
</div>
<div class="container block">
  <div class="sub-title">行业新闻</div>
  <table class="table">
    <thead>
      <tr>
        <th>新闻名称</th>
        <th>发布时间</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of news?.data">
        <td class="link" routerLink="/announcement/{{item.id}}" >{{item.title}}</td>
        <td>{{item.startDateTime}}</td>
      </tr>
    </tbody>
  </table>
  <mat-paginator
    [hidePageSize]= true
    [showFirstLastButtons]=false
    [length]=news?.paginatorInfo?.total
    [pageSize]="20"
    (page)=handleChange($event)
    >
  </mat-paginator>
</div>