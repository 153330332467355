<div class="top-block">
  <div class="container d-flex align-items-center">
    行业新闻
    <button mat-raised-button class="ml-2" (click)="location.back()">返回</button>
  </div>
</div>
<div class="container block">
  <div class="title">
    <h2 class="mb-2">{{content?.title}}</h2>
    <div class="mb-2">{{content?.startDateTime}}</div>
  </div>
  <div class="content" [innerHtml]="content?.content | safeHtml"></div>
</div>
